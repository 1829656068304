<template>
	<section class="container">
		<h5 class="text-center mb-4">Logs</h5>
		<hr />

		<!-- Search Bar -->
		<div class="mb-3">
			<b-input-group>
				<b-form-input v-model="searchQuery" placeholder="🔍 Søg i logs (Kommentar eller Filnavn)"></b-form-input>
				<b-input-group-append>
					<b-button @click="searchQuery = ''" variant="secondary">❌ Ryd</b-button>
				</b-input-group-append>
			</b-input-group>
		</div>

		<!-- Add New Log Button -->
		<div class="text-center mb-4">
			<b-button @click="toggleAddLog" variant="info">
				{{ showAddLog ? 'Annuller' : 'Tilføj ny log' }}
			</b-button>
		</div>

		<!-- Add Log Form -->
		<b-card v-if="showAddLog" class="mb-4 shadow-sm">
			<b-form @submit.prevent="saveLog">
				<b-form-group label="Dato" label-for="logDate">
					<b-form-input type="date" id="logDate" v-model="newLog.date"></b-form-input>
				</b-form-group>

				<b-form-group label="Log" label-for="logComment">
					<b-form-textarea id="logComment" v-model="newLog.comment" rows="3"></b-form-textarea>
				</b-form-group>

				<b-form-group label="Fil upload (Max 10 mb per fil)" label-for="logFiles">
					<b-form-file
						id="logFiles"
						v-model="files"
						multiple
						accept=".*"
						@change="handleFileUpload"
						placeholder="Vælg en eller flere filer..."
						drop-placeholder="Slip filer her..."
					></b-form-file>
					<!-- Show selected files preview -->
					<div v-if="files && files.length" class="mt-2">
						<div v-for="(file, index) in files" :key="index" class="d-flex align-items-center mb-1">
							<span>📎 {{ file.name }}</span>
							<b-button @click="removeSelectedFile(index)" variant="link" size="sm" class="ml-2 text-danger"> ❌ </b-button>
						</div>
					</div>
				</b-form-group>

				<BaseActionButton class="btn-update" v-bind:clicked="clicked" v-bind:disabled="clicked" type="submit">
					{{ $t('global.update') }}
				</BaseActionButton>
			</b-form>
		</b-card>
		<BaseTable :items="filteredNotes" :fields="fields" :isBusy="isBusy" class="fixed-table">
			<template #cell(action)="data">
				<b-button @click="removeLog(data.index)" variant="danger" size="sm">
					<font-awesome-icon icon="trash-alt" />
				</b-button>
			</template>

			<template #cell(date)="data">
				{{ parseDate(data.item.date) }}
			</template>

			<template #cell(userId)="data">
				{{ getUser(data.item.userId) }}
			</template>

			<!-- ✅ Download Button for File (Opens in New Tab) -->
			<template #cell(files)="data">
				<div v-if="data.item.files && data.item.files.length > 0">
					<div v-for="(file, index) in data.item.files" :key="index" class="mb-1">
						<a :href="file.filePath" target="_blank" rel="noopener noreferrer">
							<b-button variant="success" size="sm"> 📂 {{ file.fileName }}</b-button>
						</a>
					</div>
				</div>
				<div v-else>-</div>
			</template>
		</BaseTable>
	</section>
</template>

<script>
import BaseTable from '@/modules/global/components/BaseTable'
import i18n from '@/plugins/i18n'
import dateMixin from '@/mixins/dateMixin'
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'
import Swal from 'sweetalert2'
export default {
	name: 'employeeLogs',
	components: {
		BaseTable,
	},
	mixins: [dateMixin],
	async created() {
		this.$parent.$emit('updateLoader')
		this.isBusy = false
	},
	data() {
		return {
			disabled: true,
			clicked: false,
			showAddLog: false,
			searchQuery: '', // ✅ Search Query for Filtering Logs
			isBusy: false,
			newLog: {
				date: this.getTodayDate(), // ✅ Set default date
				comment: '',
				files: [],
				userId: '',
			},
			files: [],
			file: null,
			fields: [
				{ key: 'action', label: '', thStyle: { width: '20px' } },
				{ key: 'date', label: i18n.t('employeeLogs.Timestamp'), thStyle: { width: '120px' }, sortable: true },
				{
					key: 'comment',
					label: i18n.t('employeeLogs.description'),
					sortable: true,
					thStyle: { minWidth: '400px' },
					tdClass: 'comment-column',
					sortable: true,
				},
				{ key: 'userId', label: 'Rådgiver', sortable: true, thStyle: { width: '150px' }, sortable: true },
				{ key: 'files', label: 'Vedhæfte fil', sortable: false, thStyle: { width: '180px' }, sortable: true },
			],
		}
	},
	computed: {
		companyId() {
			return this.$route.params.companyId
		},
		employeePolicyId() {
			return this.$store.getters['employeeVuex/employeePolicyId']
		},
		company() {
			return this.$store.getters['employeeVuex/company']
		},
		employeePolicy() {
			return this.$store.getters['employeeVuex/employeePolicy']
		},
		notes() {
			
			return this.employeePolicy.notes.sort((a, b) => new Date(b.date) - new Date(a.date))
		},
		// Update the filtered notes computed property to handle multiple files
		filteredNotes() {
			console.log(this.notes)
			if (!this.searchQuery) {
				return this.notes
			}
			const query = this.searchQuery.toLowerCase()
			return this.notes.filter((log) => {
				const commentMatch = log.comment && log.comment.toLowerCase().includes(query)
				const fileMatch = log.files && log.files.some((file) => file.fileName && file.fileName.toLowerCase().includes(query))
				return commentMatch || fileMatch
			})
		},
	},
	methods: {
		getTodayDate() {
			// Get today's date in YYYY-MM-DD format
			const today = new Date()
			return today.toISOString().split('T')[0]
		},
		getUser(userId) {
			const users = this.$store.getters['user/users']
			var user = users.find((user) => user.id === userId)
			return user?.fullName ?? '-'
		},
		parseDate(date) {
			var options = { year: 'numeric', month: 'long', day: 'numeric' }
			return new Date(date).toLocaleDateString(options, 'da-DK')
		},
		toggleAddLog() {
			this.showAddLog = !this.showAddLog
		},
		handleFileUpload(event) {
			const maxFileSizeMB = 10
			const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024
			Array.from(event.target.files).forEach(selectedFile => {
				if (selectedFile.size > maxFileSizeBytes) {
					alert(`❌ File "${selectedFile.name}" exceeds ${maxFileSizeMB}MB and will be skipped.`)
					return
				}
				console.log(selectedFile)
				this.files.push({
					file: selectedFile,
					fileName: selectedFile.name,
				})
			})
		},

		async uploadFile(file) {
			try {
				var employeePolicyId = this.employeePolicyId
				var filePath = `employeePolicy/files/${employeePolicyId}/${file.name}`
				// Get Firebase Storage reference
				const storage = getStorage()
				const storageRef = ref(storage, filePath)
				// Upload file with metadata (preserve MIME type)
				await uploadBytes(storageRef, file, { contentType: file.type })
				console.log('✅ File uploaded successfully!')

				// Get file URL
				const downloadURL = await getDownloadURL(storageRef)
				return downloadURL // Return URL for database storage
			} catch (error) {
				console.error('❌ File upload failed:', error)
				throw error
			}
		},

		async saveLog() {
			if (this.newLog.date && this.newLog.comment) {
				this.clicked = true

				// Handle multiple file uploads
				if (this.files.length > 0) {
					try {
						const uploadedFiles = await Promise.all(
							this.files.map(async (fileObj) => {
								console.log(fileObj)
								const fileUrl = await this.uploadFile(fileObj)
								return {
									filePath: fileUrl,
									fileName: fileObj.name,
								}
							})
						)
						this.newLog.files = uploadedFiles
					} catch (error) {
						console.error('❌ File upload failed:', error)
					}
				}

				// Set userId
				this.newLog.userId = this.$store.getters['auth/userData']?.id

				// Save log entry
				this.employeePolicy.notes.push({ ...this.newLog })

				// Reset form & close modal
				this.newLog = {
					date: this.getTodayDate(),
					comment: '',
					files: [],
					userId: '',
				}
				this.files = [] // Reset files array
				this.file = null
				this.showAddLog = false

				await this.onSubmit()
				this.clicked = false
			} else {
				this.toast('Error', 'Fejl', false)
				this.clicked = false
			}
		},

		async onSubmit() {
			this.isBusy = true
			try {
				await this.$store.dispatch('employeeVuex/updateEmployeePolicy', {
					id: this.employeePolicy.id,
					data: this.employeePolicy,
					companyId: this.companyId,
					employeeId: this.employeeId,
				})
				this.toast('Success', 'Log gemt', true)
			} catch (error) {
				console.error('❌ Error updating log:', error)
			}
			this.isBusy = false
		},

		async removeLog(index) {
			Swal.fire({
				title: 'Er du sikker?',
				text: 'Denne log vil blive slettet permanent!',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Ja, slet',
				cancelButtonText: 'Annuller',
			}).then(async (result) => {
				if (result.isConfirmed) {
					const log = this.employeePolicy.notes[index]

					// Remove the log from the notes array
					this.employeePolicy.notes.splice(index, 1)
					await this.onSubmit()

					// Delete all associated files from Firebase Storage
					if (log.files && log.files.length > 0) {
						try {
							const storage = getStorage()
							await Promise.all(
								log.files.map(async (file) => {
									const storageRef = ref(storage, file.filePath)
									await deleteObject(storageRef)
								})
							)
							console.log('✅ All files deleted successfully!')
						} catch (error) {
							console.error('❌ Error deleting files:', error)
						}
					}

					Swal.fire('Slettet!', 'Loggen er blevet slettet.', 'success')
				}
			})
		},
	},
}
</script>
<style lang="scss">
@import '@/styles/pages/companyAdd.scss';
.fixed-table {
	width: 100%;
	table-layout: fixed; /* Ensures consistent column width */
	overflow-x: auto; /* Allows horizontal scrolling */
	border-collapse: collapse;
}

.fixed-table th,
.fixed-table td {
	white-space: nowrap; /* Prevents wrapping of small columns */
	overflow: hidden;
	text-overflow: ellipsis; /* Shortens text with "..." */
}

.fixed-table .comment-column {
	white-space: normal; /* Allows wrapping */
	word-break: break-word; /* Ensures long words break */
	max-width: 400px; /* Adjust this based on layout */
}
</style>
